<template>
  <div class="problem-detail-container">
    <div class="content">
      <div class="commom-panel-one" style="margin-top: 0;">
        <Form class="search-form" :label-width="85">
          <Row>
            <Col span="5">
              <Form-item label="上报日期：">
                {{create_time}}
              </Form-item>
            </Col>
            <Col span="1">&nbsp;</Col>
            <Col span="5">
              <Form-item label="会员姓名：">
                {{member_name}}
              </Form-item>
            </Col>
            <Col span="1">&nbsp;</Col>
            <Col span="5">
              <Form-item label="所属分院：">
                {{branch_name}}
              </Form-item>
            </Col>
            <Col span="1">&nbsp;</Col>
            <Col span="5">
              <Form-item label="上报来源：">
                {{source}}
              </Form-item>
            </Col>
            <Col span="1">&nbsp;</Col>
          </Row>
          <Row>
            <Col span="5">
              <Form-item label="负责部门：">
                {{charge_unit_name}}
              </Form-item>
            </Col>
            <Col span="1">&nbsp;</Col>
            <Col span="5">
              <Form-item label="问题类型：">
                {{problem_type}}
              </Form-item>
            </Col>
            <Col span="1">&nbsp;</Col>
            <Col span="5">
              <Form-item label="促进师：">
                {{booster}}
              </Form-item>
            </Col>
            <Col span="1">&nbsp;</Col>
          </Row>
          <Row>
            <Col span="24">
              <Form-item label="详细情况：">
                <span class="breakAll">{{detail_info}}</span>
              </Form-item>
            </Col>
          </Row>
          <Row>
            <Col span="24">
              <Form-item label="解决方案：">
                <span class="breakAll">{{reslove}}</span>
              </Form-item>
            </Col>
          </Row>
          <Row>
            <Col span="24">
              <Form-item label="已有附件：">
                <ul>
                  <li v-for="item in file_list" :key="item.name">
                    <a :href="item.url" target="_blank" class="download">{{item.name}}</a>
                  </li>
                </ul>
              </Form-item>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import problemService from '@/services/problemService';

export default {
	data() {
		return {
			create_time: '',
			member_name: '',
			branch_name: '',
			source: '',
			charge_unit_name: '',
			problem_type: '',
			booster: '',
			detail_info: '',
			reslove: '',
			file_list: [],
		};
	},
	computed: {
		problemId() {
			return this.$route.params.problem_id;
		},
	},
	created() {
		var param = { problem_id: this.problemId };
		problemService.getDetail(param).then((data) => {
			this.create_time = data.create_time;
			this.member_name = data.member_name;
			this.branch_name = data.branch_name;
			this.source = data.source;
			this.charge_unit_name = data.charge_unit_name;
			this.problem_type = data.problem_type;
			this.booster = data.booster;
			this.detail_info = data.detail_info;
			this.reslove = data.reslove;
			this.file_list = data.file_list;
		});
	},
};
</script>

<style lang="css" scoped>
.problem-detail-container .content {
  background: #fff;
}
</style>
